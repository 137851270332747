<template>
  <div
    id="layout-content"
    ref="mainElement"
    class="p-4 flex-1 overflow-x-hidden overflow-y-auto pb-5rem"
    @scroll="(event) => $emit('scroll', event)"
  >
    <prime-scroll-top
      v-if="scrollToTop"
      :threshold="100"
      target="parent"
      class="custom-scrolltop text-10"
      :class="{ 'has-nav': hasNav }"
      icon="i-ri-arrow-up-line"
    />
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, onMounted, onBeforeUnmount, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import PullToRefresh from 'pulltorefreshjs'

export default defineComponent({
  name: 'LayoutContent',
  props: {
    scrollToTop: {
      type: Boolean,
      default: true
    },
    pullToRefresh: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['reload', 'scroll'],
  setup (props, context) {
    const { t } = useI18n()
    const instance = ref<{ destroy:() => void }>()

    onMounted(() => {
      if (!props.pullToRefresh) return
      instance.value = PullToRefresh.init({
        mainElement: '#layout-content',
        triggerElement: '#layout-content',
        instructionsPullToRefresh: t('common.pullToRefresh'),
        instructionsReleaseToRefresh: t('common.releaseToRefresh'),
        instructionsRefreshing: t('common.isLoading'),
        distIgnore: 50,
        getStyles: () => '',
        shouldPullToRefresh () {
          return !(this.mainElement as unknown as HTMLElement).scrollTop
        },
        // getStyles: () => style,
        onRefresh: async () => {
          return await new Promise((resolve) => {
            const _unwatch = watch(() => props.loading, (loading) => {
              if (!loading) {
                _unwatch()
                void resolve()
              }
            })
            context.emit('reload')
          })
        }
      })
    })

    onBeforeUnmount(() => {
      if (!props.pullToRefresh) return
      instance.value?.destroy()
    })
    const route = useRoute()
    const hasNav = computed(() => !route.meta.hiddenTabs)

    return {
      hasNav
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(.p-scrolltop.p-link.custom-scrolltop) {
  @apply w-12.5 h-12.5 rounded-99 bottom-2rem right-4 bg-truegray-700 op-50 fixed;
  &.has-nav {
    @apply bottom-[calc(60px+2rem+var(--safe-area-inset-bottom))] md:bottom-2rem;
  }
  .p-scrolltop-icon {
    @apply text-6 color-[var(--primary-color-text)];
  }
}

:global(.ptr--ptr) {
  pointer-events: none;
  font-size: 0.85em;
  font-weight: bold;
  top: 0;
  height: 0;
  transition: height 0.3s, min-height 0.3s;
  text-align: center;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  align-content: stretch;
}
:global(.ptr--box) {
  padding: 10px;
  flex-basis: 100%;
}
:global(.ptr--pull) {
  transition: none;
}
:global(.ptr--text) {
  margin-top: .33em;
  color: rgba(0, 0, 0, 0.3);
}
:global(.ptr--icon) {
  color: rgba(0, 0, 0, 0.3);
  transition: transform .3s;
}
/*
When at the top of the page, disable vertical overscroll so passive touch
listeners can take over.
*/
:global(.ptr--top) {
  touch-action: pan-x pan-down pinch-zoom;
}
:global(.ptr--release .ptr--icon) {
  transform: rotate(180deg);
}
</style>
