<template>
  <span :class="cashClass">
    {{ formatted }}
  </span>
</template>

<script setup lang="ts">
import { defineOptions, computed } from 'vue'
import { formatCurrency, formatCurrencyColor, formatRoundDown } from '@/utils/format'
import { useI18n } from 'vue-i18n'

defineOptions({
  name: 'BaseCash'
})

const props = withDefaults(defineProps<{
  cash: string | number | null,
  format?: 'currency' | 'decimal' | 'usdt',
  noColor?: boolean,
  negative?: boolean
}>(), {
  format: 'decimal',
  noColor: false,
  negative: false
})

const { t } = useI18n()
const cash = computed(() => {
  const value = Number(props.cash ?? 0)
  if (props.negative && value > 0) return value * -1
  return value
})

const cashClass = computed(() => {
  if (props.noColor) return
  const result = formatRoundDown(cash.value, props.format)
  return formatCurrencyColor(result)
})
const formatted = computed(() => {
  if (props.cash === null) return '-'
  return formatCurrency(cash.value, { style: props.format })
})
</script>
