<template>
  <prime-dialog
    :dismissable-mask="true"
    :modal="true"
    :position="position"
    :breakpoints="breakpoints"
    :style="{ width, margin: 0 }"
    :show-header="showHeader"
    :close-on-escape="false"
    :draggable="false"
    class="base-media-query-dialog min-h-50vh md:min-h-auto"
  >
    <template
      v-if="$slots.header"
      #header
    >
      <slot name="header" />
    </template>
    <slot />
    <template
      v-if="$slots.footer"
      #footer
    >
      <slot name="footer" />
    </template>
  </prime-dialog>
</template>

<script setup lang="ts">
import { defineOptions, ref, computed } from 'vue'
import { useMediaQueryStore } from '@/stores/screen'
import { storeToRefs } from 'pinia'
import { BreakPoints } from '@/constants/BreakPoints'

defineOptions({
  name: 'BaseMediaQueryDialog'
})

withDefaults(defineProps<{
  showHeader?: boolean,
  width?: string
}>(), {
  showHeader: true,
  width: '650px'
})

const { isMobile } = storeToRefs(useMediaQueryStore())
const breakpoints = ref({ [`${BreakPoints.md}px`]: '100vw' })
const position = computed(() => isMobile.value ? 'bottom' : 'center')
</script>

<style lang="scss" scoped>
:global(.p-dialog-mask.p-component-overlay) {
  @apply right-0 bottom-0 h-auto;
}

:global(.p-dialog.base-media-query-dialog) {
  @apply pb-[var(--safe-area-inset-bottom)];
}

:global(.p-dialog.base-media-query-dialog .p-dialog-header) {
  @apply rounded-t-1.5 p-0 relative fw-700;
}
:global(.p-dialog.base-media-query-dialog .p-dialog-footer) {
  @apply rounded-b-1.5;
}
:global(.p-dialog.base-media-query-dialog .p-dialog-content) {
  @apply bg-[var(--surface-100)] p-0 first:rounded-t-1.5 last:rounded-b-1.5;
}

:global(.p-dialog-bottom .p-dialog.base-media-query-dialog .p-dialog-footer) {
  @apply rounded-0;
}
:global(.p-dialog-bottom .p-dialog.base-media-query-dialog .p-dialog-content) {
  @apply last:rounded-0;
}

:global(.p-dialog.base-media-query-dialog .p-dialog-header .p-dialog-header-icons) {
  @apply hidden md:block absolute right-16px top-[calc(50%-14px)];
}

:global(.p-dialog.base-media-query-dialog .p-dialog-header .p-dialog-header-icon) {
  @apply color-cool-gray-600;
}
</style>
