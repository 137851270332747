<template>
  <div class="relative h-full w-full flex-1">
    <div class="absolute top-30% w-full flex-col-center">
      <img
        src="@/assets/images/noData.png"
        alt="noData"
        class="mb-7px w-80px h-80px"
      >
      <div class="color-[var(--text-color-secondary)]">
        {{ t('common.noData') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineOptions } from 'vue'
import { useI18n } from 'vue-i18n'

defineOptions({
  name: 'BaseNoData'
})

const { t } = useI18n()
</script>
