<template>
  <span class="p-buttonset base-group-date-button">
    <prime-button
      :icon="'i-ri-calendar-line'"
      :label="buttonLabel"
      class="p-button-sm"
      :class="{ 'p-button-outlined': !model }"
      @click="click"
    />
    <base-date-button
      v-bind="$attrs"
      v-model="model"
      :show-icon="false"
      :show-shortcuts="showShortcuts"
      :clearable="clearable"
      :min-date="minDate"
    />
  </span>
</template>

<script setup lang="ts">
import { defineOptions } from 'vue'
import { DateShortcutType, getTaipeiNowTime, startOfMonthTaipei } from '@/utils/TimeHelper'
import { subMonths } from 'date-fns'

defineOptions({
  name: 'BaseGroupDateButton',
  inheritAttrs: false
})

withDefaults(defineProps<{
  buttonLabel?: string,
  showShortcuts?: DateShortcutType[],
  minDate?: Date,
  clearable?: boolean
}>(), {
  buttonLabel: '',
  showShortcuts: () => (['today', 'yesterday', 'thisWeek', 'lastWeek', 'thisMonth', 'lastMonth']),
  minDate: () => startOfMonthTaipei(subMonths(getTaipeiNowTime(), 1)),
  clearable: false
})

const emit = defineEmits(['click'])

const model = defineModel<Date[] | Date | undefined>({ default: undefined })

const click = () => {
  emit('click')
}
</script>

<style lang="scss" scoped>
.base-group-date-button {
  :deep(.p-button) {
    @apply focus:shadow-none;
  }
}
</style>
