<template>
  <div class="-m-2">
    <div class="flex flex-wrap">
      <div
        v-for="item in options"
        :key="item.value"
        :class="{ 'p-button-outlined': !model.includes(item.value) }"
        class="w-33.33% px-2 pt-2"
      >
        <prime-button
          class="p-button-rounded p-2 md:px-3 w-full"
          :class="{ 'p-button-outlined': !model.includes(item.value) }"
          @click="() => onClick(item.value)"
        >
          <span class="text-xs md:text-sm mx-auto">
            {{ item.name }}
          </span>
        </prime-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineOptions } from 'vue'

defineOptions({
  name: 'BaseStatusSelect'
})

withDefaults(defineProps<{
  options?: Array<{ name: string, value: number | string }>
}>(), {
  options: () => ([])
})

const model = defineModel<Array<number | string>>({ default: () => ([]) })

const onClick = (value: number | string) => {
  const index = model.value.findIndex(item => item === value)
  const newValue = [...model.value]
  if (index === -1) {
    newValue.push(value)
  } else {
    newValue.splice(index, 1)
  }
  model.value = newValue
}
</script>
