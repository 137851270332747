import { startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, addDays, subDays, subWeeks, subMonths, addMonths } from 'date-fns'
import { NowTime } from '@golden/gdk-agent-next'
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

export type DateShortcutType = 'today' | 'yesterday' | 'thisWeek' | 'lastWeek' | 'nextThisWeek' | 'nextOneWeek' | 'nextTwoWeeks' | 'thisMonth' | 'lastMonth' | 'nextOneMonth' | 'nextTwoMonths'

const TAIPEI_TIMEZONE = '+08'

export function getTaipeiTime (utc: string | number | Date) {
  return utcToZonedTime(utc, TAIPEI_TIMEZONE)
}

export function getTaipeiNowTime () {
  return getTaipeiTime(NowTime.get())
}

export function formatTaipeiTime (date: string | number | Date, pattern: string) {
  return formatInTimeZone(date, TAIPEI_TIMEZONE, pattern)
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createTaipeiDateFunc <T extends (...args: any[]) => Date> (func: T) {
  return (...args: Parameters<T>) => zonedTimeToUtc(func(...args), TAIPEI_TIMEZONE)
}
export const startOfDayTaipei = createTaipeiDateFunc(startOfDay)
export const endOfDayTaipei = createTaipeiDateFunc(endOfDay)
export const startOfWeekTaipei = createTaipeiDateFunc(startOfWeek)
export const endOfWeekTaipei = createTaipeiDateFunc(endOfWeek)
export const startOfMonthTaipei = createTaipeiDateFunc(startOfMonth)
export const endOfMonthTaipei = createTaipeiDateFunc(endOfMonth)

export function setToday () {
  return { start: startOfDayTaipei(getTaipeiNowTime()), end: endOfDayTaipei(getTaipeiNowTime()) }
}

export function setYesterday () {
  const yesterday = subDays(getTaipeiNowTime(), 1)
  return { start: startOfDayTaipei(yesterday), end: endOfDayTaipei(yesterday) }
}

export function setTwoDaysLater () {
  const twoDaysLater = endOfDayTaipei(addDays(getTaipeiNowTime(), 2))
  return { start: startOfDayTaipei(getTaipeiNowTime()), end: twoDaysLater }
}

export function setLastSevenDays () {
  return { start: startOfDayTaipei(subDays(getTaipeiNowTime(), 6)), end: endOfDayTaipei(getTaipeiNowTime()) }
}

export function setThisWeek () {
  return { start: startOfWeekTaipei(getTaipeiNowTime(), { weekStartsOn: 1 }), end: endOfDayTaipei(getTaipeiNowTime()) }
}

export function setNextThisWeek () {
  return { start: startOfDayTaipei(getTaipeiNowTime()), end: endOfWeekTaipei(getTaipeiNowTime(), { weekStartsOn: 1 }) }
}

export function setLastWeek () {
  return { start: startOfWeekTaipei(subWeeks(getTaipeiNowTime(), 1), { weekStartsOn: 1 }), end: endOfWeekTaipei(subWeeks(getTaipeiNowTime(), 1), { weekStartsOn: 1 }) }
}

export function setNextOneWeek () {
  return { start: startOfDayTaipei(getTaipeiNowTime()), end: endOfDayTaipei(addDays(getTaipeiNowTime(), 6)) }
}

export function setNextTwoWeeks () {
  return { start: startOfDayTaipei(getTaipeiNowTime()), end: endOfDayTaipei(addDays(getTaipeiNowTime(), 13)) }
}

export function setNextOneMonth () {
  return { start: startOfDayTaipei(getTaipeiNowTime()), end: endOfDayTaipei(addMonths(getTaipeiNowTime(), 1)) }
}

export function setNextTwoMonths () {
  return { start: startOfDayTaipei(getTaipeiNowTime()), end: addMonths(endOfDayTaipei(getTaipeiNowTime()), 2) }
}

export function setThisMonth () {
  return { start: startOfMonthTaipei(getTaipeiNowTime()), end: endOfDayTaipei(getTaipeiNowTime()) }
}

export function setLastMonth () {
  const oneDayInLastMonth = subMonths(getTaipeiNowTime(), 1)
  return { start: startOfMonthTaipei(oneDayInLastMonth), end: endOfMonthTaipei(oneDayInLastMonth) }
}
