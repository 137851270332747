<template>
  <prime-button
    icon="i-ri-search-line"
    :label="accountModel || label"
    :class="{ 'p-button-outlined': accountModel === '', 'p-button-sm': true }"
    @click="openDialog"
  >
    <span class="i-ri-search-line p-button-icon p-button-icon-left" />
    <span
      class="truncate fw-bold"
      :style="{ maxWidth: labelWidth }"
    >
      {{ accountModel || label }}
    </span>
    <i
      v-if="accountModel?.length"
      class="i-ri-close-circle-fill cursor-pointer px-2 ml-2"
      @click.stop="clear"
    />
  </prime-button>
  <base-media-query-dialog
    v-model:visible="visibleModel"
    class="player-search-dialog"
    :class="{ 'empty': empty && !$slots.subToolbar }"
    @after-hide="afterHide"
  >
    <template #header>
      <div class="w-full flex-row-between mb-2">
        <div class="mr-4 w-full">
          <base-input
            ref="inputRef"
            v-model="inputValue"
            class="w-full"
            :placeholder="t('common.search')"
            @update:model-value="handleInput"
            @focus="focus"
            @blur="blur"
          >
            <template #left>
              <i class="i-ri-search-line" />
            </template>
          </base-input>
        </div>
        <prime-button
          class="p-button-link link p-0 shrink-0"
          :label="t('common.cancel')"
          aria-label="close"
          @click="closeDialog"
        />
      </div>
      <div
        v-if="$slots.toolbar && inputValue.length > 1"
        v-show="inputValue.length > 1"
        class="flex algin-items-center block w-full py-2 flex-wrap"
      >
        <slot name="toolbar" />
      </div>
    </template>
    <div
      v-if="(inputValue.length < 2) || $slots.subToolbar"
      class="w-full p-4 color-[var(--text-color)]"
    >
      <template v-if="inputValue.length < 2">
        {{ t('common.recentSearch') }}
      </template>
      <slot
        v-else
        name="subToolbar"
      />
    </div>
    <div
      class="overflow-y-auto flex-1 min-h-1px"
      :class="{ 'mt-8px': inputValue.length >= 2 }"
      @scroll="(e) => $emit('scroll', e)"
    >
      <slot />
      <div
        v-if="isFirstLoad || empty"
        class="flex-row-center h-full"
      >
        <span v-if="isFirstLoad">
          {{ t('common.isSearching') }}
        </span>
        <base-no-data v-else-if="empty" />
      </div>
      <div
        v-else-if="loading || finished"
        class="py-2 flex-row-center"
      >
        <span v-if="loading">
          {{ t('common.isLoading') }}
        </span>
        <span v-else-if="finished">
          {{ t('common.scrollFinished') }}
        </span>
      </div>
    </div>
  </base-media-query-dialog>
</template>

<script setup lang="ts">
import { defineOptions, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useObservable } from '@golden/shared-vue'
import { fromEvent, tap } from 'rxjs'
import i18n from '@/i18n'

defineOptions({
  name: 'BaseAccountSearchButton'
})

withDefaults(defineProps<{
  label?: string,
  isFirstLoad?: boolean,
  loading?: boolean,
  empty?: boolean,
  finished?: boolean,
  height?: number,
  labelWidth?: string
}>(), {
  label: i18n.global.t('common.account'),
  isFirstLoad: false,
  loading: false,
  empty: false,
  finished: false,
  height: 400,
  labelWidth: undefined
})

const emit = defineEmits(['input', 'scroll', 'clear', 'after-hide'])

const accountModel = defineModel<string | undefined>('account', { default: '' })
const visibleModel = defineModel<boolean>('visible', { default: false })

const { t } = useI18n()
const inputRef = ref<{ $el: HTMLElement }>()
const inputValue = ref('')

const openDialog = () => {
  visibleModel.value = true
  inputValue.value = ''
  emit('input', '')
}
const closeDialog = () => {
  visibleModel.value = false
}

const handleInput = (value: string) => {
  emit('input', value)
}

const clear = () => {
  emit('clear')
  accountModel.value = undefined
}
const afterHide = () => {
  emit('after-hide')
}
const focus = () => {
  window.focus()
}
const blur = () => {
  window.blur()
}

useObservable(fromEvent(document, 'touchstart').pipe(tap(() => {
  inputRef.value?.$el.querySelector('input')?.blur()
})))
</script>

<style lang="scss" scoped>
:global(.p-dialog.player-search-dialog) {
  @apply max-h-[calc(100%-60px)] h-[calc(100%-60px)] important-mt-60px md:max-h-500px md:h-500px md:important-mt-0;
}

:global(.p-dialog.player-search-dialog .p-dialog-content) {
  @apply flex-1 flex flex-col;
}
:global(.p-dialog.player-search-dialog .p-dialog-header) {
  @apply flex-wrap pt-10px px-15px pb-0;
}

:global(.p-dialog.player-search-dialog.empty .p-dialog-header) {
  @apply md:rounded-b-1.5;
}

:global(.p-dialog.player-search-dialog .p-dialog-header .p-dialog-header-icons) {
  @apply hidden;
}

:global(.p-dialog.player-search-dialog .p-dialog-footer) {
  @apply p-0;
}
</style>
