import { RouteNameType } from './../constants/routeNameMapPermission'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import i18n from '@/i18n'
import { authGuard, queryGuard, saveScrollPositionGuard, scrollBehavior, pipe, permissionGuard, assistantGuard, childrenRedirect } from './guards'
import { useLayoutStore } from '@/stores/layout'
import { useQueryStore } from '@/stores/query'
import { setThisMonth } from '@/utils/TimeHelper'
import { useReportDefaultQuery } from '@/functions/useReport'
import gdk from '@/gdk'
import Prepare from '../views/PrepareView.vue'
import Login from '../views/LoginView.vue'
import Home from '../views/HomeView.vue'
import Bet from '../views/report/BetView.vue'
import Players from '../views/users/PlayersView.vue'
import Transaction from '../views/report/TransactionView.vue'

/* eslint-disable @typescript-eslint/no-explicit-any */
interface ToastMessageOptions {
  /**
   * Severity level of the message.
   * @see ToastMessageSeverityType
   * Default value is 'info'.
   */
  severity?: any
  /**
   * Summary content of the message.
   */
  summary?: string | undefined
  /**
   * Detail content of the message.
   */
  detail?: any
  /**
   * Whether the message can be closed manually using the close icon.
   * Default value is true.
   */
  closable?: boolean | undefined
  /**
   * Delay in milliseconds to close the message automatically.
   * Default value is 3000.
   */
  life?: number | undefined
  /**
   * Key of the Toast to display the message.
   */
  group?: string | undefined
  /**
   * Style class of the message.
   */
  styleClass?: any
  /**
   * Style class of the content.
   */
  contentStyleClass?: any
}
/* eslint-enable @typescript-eslint/no-explicit-any */
interface ToastServiceMethods {
  /**
   * Displays the message in a suitable Toast component.
   * @param {ToastMessageOptions} message - Message instance.
   */
  add: (message: ToastMessageOptions) => void
  /**
   * Clears the messages that belongs to the group.
   * @param {string} group - Name of the message group.
   */
  removeGroup: (group: string) => void
  /**
   * Clears all the messages.
   */
  removeAllGroups: () => void
}
export let toast: ToastServiceMethods

export const registerRouterToast = (callback: ToastServiceMethods) => {
  toast = callback
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/prepare/',
    name: RouteNameType.PREPARE,
    component: Prepare,
    props: (route) => ({ toPath: route.query.to }),
    meta: {
      noCheckLogin: true,
      withoutLayout: true
    }
  },
  {
    path: '/login',
    name: RouteNameType.LOGIN,
    component: Login,
    children: [],
    meta: {
      notLogin: true,
      withoutLayout: true
    }
  },
  {
    path: '/logout',
    name: RouteNameType.LOGOUT,
    beforeEnter: (_to, _from, next) => {
      const store = useQueryStore()
      store.$reset()

      gdk.auth.logout().subscribe()
      next({ path: '/login' })
    },
    component: Home,
    meta: {
      noCheckLogin: true,
      withoutLayout: true
    }
  },
  {
    path: '/home',
    name: RouteNameType.HOME,
    component: Home,
    children: []
  },
  {
    path: '/users',
    name: RouteNameType.USERS,
    redirect: () => {
      return childrenRedirect([RouteNameType.PLAYERS, RouteNameType.AGENTS, RouteNameType.SUB_ACCOUNT])
    },
    component: async () => await import('../views/UsersView.vue'),
    children: [
      {
        path: 'players',
        children: [
          {
            path: '',
            name: RouteNameType.PLAYERS,
            beforeEnter: queryGuard('player'),
            component: Players
          },
          {
            path: ':id',
            name: RouteNameType.PLAYER_DETAIL,
            beforeEnter: saveScrollPositionGuard(['.p-datatable-wrapper']),
            component: async () => await import('../views/users/PlayersDetailView.vue'),
            meta: {
              hiddenTabs: true,
              customBreadCrumb: true
            }
          }
        ]
      },
      {
        path: 'agents',
        children: [
          {
            path: '',
            name: RouteNameType.AGENTS,
            component: async () => await import('../views/users/AgentsView.vue')
          },
          {
            path: ':id',
            name: RouteNameType.AGENTS_DETAIL,
            beforeEnter: saveScrollPositionGuard(['.p-datatable-wrapper']),
            component: async () => await import('../views/users/AgentsDetailView.vue'),
            props: (to) => ({ id: Number(to.params.id) || -1 }),
            meta: {
              hiddenTabs: true,
              customBreadCrumb: true
            }
          },
          {
            path: 'agents-add',
            name: RouteNameType.AGENTS_ADD,
            beforeEnter: pipe(assistantGuard, saveScrollPositionGuard(['.p-datatable-wrapper'])),
            component: async () => await import('../views/users/AgentsAddView.vue'),
            meta: {
              hiddenTabs: true
            }
          }
        ]
      },
      {
        path: 'sub-account',
        children: [
          {
            path: '',
            name: RouteNameType.SUB_ACCOUNT,
            component: async () => await import('../views/users/SubAccountView.vue')
          },
          {
            path: ':id',
            name: RouteNameType.SUB_ACCOUNT_DETAIL,
            beforeEnter: saveScrollPositionGuard(['.p-datatable-wrapper']),
            component: async () => await import('../views/users/SubAccountDetailView.vue'),
            props: (to) => ({ id: Number(to.params.id) || -1 }),
            meta: {
              hiddenTabs: true,
              customBreadCrumb: true
            }
          },
          {
            path: 'sub-account-add',
            name: RouteNameType.SUB_ACCOUNT_ADD,
            beforeEnter: saveScrollPositionGuard(['.p-datatable-wrapper']),
            component: async () => await import('../views/users/SubAccountAddView.vue'),
            meta: {
              hiddenTabs: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/promote',
    name: RouteNameType.PROMOTE,
    redirect: () => {
      return childrenRedirect([RouteNameType.PROMOTE_URL, RouteNameType.PROMOTE_ACTIVITIES])
    },
    component: async () => await import('../views/PromoteView.vue'),
    children: [
      {
        path: 'promote-url',
        name: RouteNameType.PROMOTE_URL,
        component: async () => await import('../views/promote/PromoteUrlView.vue')
      },
      {
        path: 'promote-activities',
        name: RouteNameType.PROMOTE_ACTIVITIES,
        component: async () => await import('../views/promote/PromoteActivitiesView.vue')
      }
    ]
  },
  {
    path: '/report',
    name: RouteNameType.REPORT,
    redirect: () => {
      return childrenRedirect([RouteNameType.COMMISSION, RouteNameType.BET, RouteNameType.ACTIVITY, RouteNameType.TRANSACTION])
    },
    component: async () => await import('../views/ReportView.vue'),
    children: [
      {
        path: 'commission',
        children: [
          {
            path: '',
            name: RouteNameType.COMMISSION,
            beforeEnter: (to, from, next) => {
              const { start, end } = setThisMonth()
              const defaultQuery = useReportDefaultQuery()
              return queryGuard('commission', {
                ...defaultQuery.value,
                settled_start_at: start.getTime(),
                settled_end_at: end.getTime()
              })(to, from, next)
            },
            component: async () => await import('../views/report/CommissionView.vue')
          },
          {
            path: 'commission-detail',
            name: RouteNameType.COMMISSION_DETAIL_COMMISSION,
            component: async () => await import('../views/report/CommissionDetailCommissionView.vue'),
            beforeEnter: saveScrollPositionGuard(['#layout-content']),
            meta: {
              hiddenTabs: true
            }
          },
          {
            path: 'feedback-detail',
            name: RouteNameType.COMMISSION_DETAIL_FEEDBACK,
            component: async () => await import('../views/report/CommissionDetailFeedbackView.vue'),
            beforeEnter: saveScrollPositionGuard(['#layout-content']),
            meta: {
              hiddenTabs: true
            }
          },
          {
            path: 'platform-fee-detail',
            name: RouteNameType.COMMISSION_DETAIL_PLATFORM_FEE,
            component: async () => await import('../views/report/CommissionDetailPlatformFeeView.vue'),
            meta: {
              hiddenTabs: true
            }
          },
          {
            path: 'risk-adjustment-detail',
            name: RouteNameType.COMMISSION_DETAIL_RISK_ADJUSTMENT,
            component: async () => await import('../views/report/CommissionDetailRiskAdjustmentView.vue'),
            meta: {
              hiddenTabs: true
            }
          },
          {
            path: 'finance-fee-detail',
            name: RouteNameType.COMMISSION_DETAIL_FINANCE_FEE,
            component: async () => await import('../views/report/CommissionDetailFinanceFeeView.vue'),
            meta: {
              hiddenTabs: true
            }
          }
        ]
      },
      {
        path: 'bet',
        children: [
          {
            path: '',
            name: RouteNameType.BET,
            beforeEnter: pipe((to, from, next) => {
              const { start, end } = setThisMonth()
              const defaultQuery = useReportDefaultQuery()
              return queryGuard('bet', {
                ...defaultQuery.value,
                search_type: 'settled_at',
                start_at: start.getTime(),
                end_at: end.getTime()
              })(to, from, next)
            }),
            component: Bet
          },
          {
            path: 'detail',
            name: RouteNameType.BET_DETAIL,
            beforeEnter: saveScrollPositionGuard(['#layout-content', '.p-datatable-wrapper']),
            component: async () => await import('../views/report/BetViewDetail.vue'),
            meta: {
              hiddenTabs: true,
              customBreadCrumb: true
            }
          }
        ]
      },
      {
        path: 'activity',
        children: [
          {
            path: '',
            name: RouteNameType.ACTIVITY,
            component: async () => await import('../views/report/ActivityView.vue'),
            beforeEnter: pipe((to, from, next) => {
              const { start, end } = setThisMonth()
              const defaultQuery = useReportDefaultQuery()
              return queryGuard('activity', {
                ...defaultQuery.value,
                start_at: start.getTime(),
                end_at: end.getTime()
              })(to, from, next)
            })
          },
          {
            path: ':id',
            name: RouteNameType.ACTIVITY_DETAIL,
            component: async () => await import('../views/report/ActivityDetailView.vue'),
            beforeEnter: saveScrollPositionGuard(['#layout-content', '.p-datatable-wrapper']),
            meta: {
              hiddenTabs: true,
              customBreadCrumb: true
            }
          }
        ]
      },
      {
        path: 'transaction',
        children: [
          {
            path: '',
            name: RouteNameType.TRANSACTION,
            beforeEnter: pipe((to, from, next) => {
              const { start, end } = setThisMonth()
              const defaultQuery = useReportDefaultQuery()
              return queryGuard('transaction', {
                ...defaultQuery.value,
                search_type: 'finished_at',
                start_at: start.getTime(),
                end_at: end.getTime()
              })(to, from, next)
            }),
            component: Transaction
          },
          {
            path: ':id',
            name: RouteNameType.TRANSACTION_DETAIL,
            component: async () => await import('../views/report/TransactionDetailView.vue'),
            beforeEnter: saveScrollPositionGuard(['#layout-content', '.p-datatable-wrapper']),
            meta: {
              hiddenTabs: true,
              customBreadCrumb: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/me',
    children: [
      {
        path: '',
        name: RouteNameType.ME,
        component: async () => await import('../views/MeView.vue')
      },
      {
        path: 'announcement',
        name: RouteNameType.ANNOUNCEMENT,
        component: async () => await import('../views/me/AnnouncementView.vue'),
        meta: {
          hiddenTabs: true
        }
      },
      {
        path: 'setting',
        name: RouteNameType.SETTING,
        component: async () => await import('../views/me/SettingView.vue'),
        meta: {
          hiddenTabs: true
        }
      },
      {
        path: 'agent-announcement',
        name: RouteNameType.AGENT_ANNOUNCEMENT,
        component: async () => await import('../views/me/AgentAnnouncementView.vue'),
        meta: {
          hiddenTabs: true
        }
      },
      {
        path: 'agent-deposit',
        meta: {
          hiddenTabs: true
        },
        children: [
          {
            path: '',
            name: RouteNameType.AGENT_DEPOSIT,
            component: async () => await import('../views/me/AgentDepositView.vue')
          }
        ]
      },
      {
        path: 'deposit',
        meta: {
          hiddenTabs: true
        },
        children: [
          {
            path: '',
            name: RouteNameType.DEPOSIT,
            component: async () => await import('../views/me/DepositView.vue')
          }
        ]
      },
      {
        path: 'transfer',
        meta: {
          hiddenTabs: true
        },
        children: [
          {
            path: '',
            name: RouteNameType.TRANSFER,
            component: async () => await import('../views/me/TransferView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/record',
    redirect: '/record/wallet-record',
    component: async () => await import('../views/RecordView.vue'),
    meta: {
      hiddenTabs: true,
      customBreadCrumb: true
    },
    children: [
      {
        path: 'wallet-record',
        name: RouteNameType.RECORD,
        component: async () => await import('../views/record/WalletRecordView.vue'),
        meta: {
          customBreadCrumb: true
        }
      },
      {
        path: 'agent-deposit-record',
        name: RouteNameType.AGENT_DEPOSIT_RECORD,
        component: async () => await import('../views/record/AgentDepositRecordView.vue'),
        meta: {
          customBreadCrumb: true
        }
      },
      {
        path: 'deposit-record',
        name: RouteNameType.DEPOSIT_RECORD,
        component: async () => await import('../views/record/DepositRecordView.vue'),
        meta: {
          customBreadCrumb: true
        }
      },
      {
        path: 'transfer-record',
        name: RouteNameType.TRANSFER_RECORD,
        component: async () => await import('../views/record/TransferRecordView.vue'),
        meta: {
          customBreadCrumb: true
        }
      }
    ]
  },
  {
    path: '/leaderboard',
    name: RouteNameType.LEADERBOARD,
    component: async () => await import('../views/LeaderboardView.vue'),
    meta: {
      hiddenTabs: true
    }
  },
  {
    path: '/no-network',
    name: RouteNameType.NO_NETWORK,
    component: async () => await import('../views/NoNetworkView.vue'),
    meta: {
      noCheckAuth: true,
      hiddenTabs: true,
      withoutLayout: true
    }
  },
  {
    path: '/load-failed',
    name: RouteNameType.LOAD_FAILED,
    component: async () => await import('../views/LoadFailedView.vue'),
    meta: {
      noCheckAuth: true,
      hiddenTabs: true,
      withoutLayout: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: RouteNameType.NOT_FOUND,
    component: async () => await import('../views/NotFoundView.vue'),
    meta: {
      noCheckAuth: true,
      hiddenTabs: true,
      withoutLayout: true
    }
  }
]

const router = createRouter({
  scrollBehavior,
  history: createWebHistory('/'),
  routes
})

router.beforeEach(pipe(
  authGuard,
  permissionGuard
))

router.afterEach((to) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { setBreadCrumb } = useLayoutStore()
  if (!to.meta.customBreadCrumb) {
    const breadcrumb = to.path.split('/')
    const value = breadcrumb.map((item, index) => ({ label: item ? i18n.global.t(`page.${item}`) : '', to: breadcrumb.slice(0, index + 1).join('/') }))
    setBreadCrumb(value.slice(breadcrumb.length > 3 ? -2 : -1))
  }
  window.document.title = `${i18n.global.t(`page.${to.name as string}`)} | 管理后台`
})

export default router
