<template>
  <base-input
    v-model="model"
    v-bind="$attrs"
    class="password"
    :type="show ? 'text' : 'password'"
  >
    <template #rightIcon="{ clearable }">
      <i
        v-show="clearable"
        class="i-ri-close-circle-fill ml-2 bg-truegray-500 cursor-pointer p-inputgroup-addon right-10 color-[#6c757d]"
        @click="clear"
      />
      <i
        class="cursor-pointer p-inputgroup-addonbg-truegray-500 mx-2 text-5"
        :class="{ 'i-ri-eye-line': show, 'i-ri-eye-off-line': !show }"
        @click="toggle"
      />
    </template>
  </base-input>
</template>

<script setup lang="ts">
import { defineOptions, ref } from 'vue'

defineOptions({
  name: 'BasePassword'
})

const model = defineModel<string>({ default: '' })

const clear = () => {
  model.value = ''
}

const show = ref(false)
const toggle = () => {
  show.value = !show.value
}
</script>
