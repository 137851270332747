<template>
  <span
    v-tooltip.top="tooltip ?? data"
    class="truncate"
    :class="{ link: !disabled }"
    @click.stop="openDialog"
  >
    <slot>{{ data || t('common.notSet') }}</slot>
  </span>
</template>

<script setup lang="ts">
import { defineOptions, DefineComponent } from 'vue'
import { useDialog } from '@/functions/useDialog'
import { useI18n } from 'vue-i18n'
import { SetableDataCallbackPayload } from '@/utils/TableHelper'

defineOptions({
  name: 'BaseSetableData'
})

const props = withDefaults(defineProps<{
  id: number,
  dialog: DefineComponent<unknown, unknown, any>,
  callback:(payload: SetableDataCallbackPayload<any>) => Promise<void>,
  disabled?: boolean,
  data?: string,
  tooltip?: string,
  dialogData?: Record<string, any>,
  toast?: string
}>(), {
  disabled: false,
  data: '',
  tooltip: undefined,
  dialogData: () => ({}),
  toast: undefined
})

const { t } = useI18n()
const { setData } = useDialog()

const openDialog = () => {
  if (props.disabled) return
  setData(props.dialog, { id: props.id, data: props.data, dialogData: { ...props.dialogData }, callback: props.callback, msg: props.toast })
}
</script>
