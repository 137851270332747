<template>
  <game-svg-sprite />
  <div
    v-if="route.meta.withoutLayout"
    :class="{ [getWebpClass()]: true, pwa: isPWA() }"
    class="absolute-full bg-[var(--gray-100)]"
  >
    <router-view />
  </div>
  <div
    v-else
    id="layout"
    :class="{ [getWebpClass()]: true, pwa: isPWA() }"
  >
    <desktop-sidebar />
    <div class="flex flex-grow-1 flex-col md:h-full overflow-x-hidden">
      <desktop-toolbar />
      <main class="flex-1 flex flex-col overflow-auto">
        <router-view />
      </main>
    </div>
    <mobile-nav-tabs />
  </div>
  <prime-toast
    position="top-center"
    class="mt-[var(--safe-area-inset-top)]"
  />
  <prime-dynamic-dialog />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { storeToRefs } from 'pinia'
import { useMediaQueryStore } from '@/stores/screen'
import { useAgentStore } from '@/stores/agent'
import { registerRouterToast } from '@/router'
import { getWebpClass } from '@golden/shared'
import { registerDefaultGDKErrorHandler, authErrorCodes, LoginStatusStorage } from '@golden/shared-vue'
import { sendErrorToSentry } from '@golden/sentry-vue'
import { GDKError, GDKFormError, Permissions } from '@golden/gdk-agent-next'
import { RouteNameType, routeNameMapPermissions } from './constants/routeNameMapPermission'
import gdk from './gdk'
import { useConfigureAgentGA } from '@golden/ga-vue'
import { filter, throttle, pairwise, timer, of, map } from 'rxjs'
import { isPWA } from '@golden/app-plugins'

export default defineComponent({
  name: 'App',
  setup () {
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const { isDesktop, isMobile } = storeToRefs(useMediaQueryStore())
    const agentStore = useAgentStore()

    const toast = useToast()
    registerRouterToast(toast)
    registerDefaultGDKErrorHandler((authErrorCodes) => () => {
      return (error: GDKError | GDKFormError): void => {
        if (authErrorCodes.includes(error.code)) return
        toast.add({ severity: 'error', detail: error.message, life: 1000 })
      }
    })

    if (!['local', 'alpha', 'beta'].includes(import.meta.env.VITE_APP_RELEASE_STAGE)) {
      if (!import.meta.env.VITE_APP_GA_ID) throw new Error('Please set VITE_APP_GA_ID env for google analytics.')
      useConfigureAgentGA(gdk)
    }

    gdk.error.pipe(
      pairwise(),
      throttle(([prev, curr]) => prev.code === curr.code ? timer(1000) : of(1)),
      map(([, curr]) => curr),
      filter((error) => authErrorCodes.includes(error.code) && LoginStatusStorage.isCheck)
    ).subscribe(() => {
      if (route.name === RouteNameType.PREPARE || route.name === RouteNameType.LOGIN) return
      void router.push({ name: RouteNameType.LOGIN })
    })
    gdk.socket.connect()
    gdk.socket.handleReconnectError = (error: Error) => sendErrorToSentry({
      error,
      config: { tags: { websocket: 'v1' } }
    })

    agentStore.$subscribe((_, state) => {
      if (routeNameMapPermissions[route.name as RouteNameType].every((el) => state.me?.permissions.includes(el))) return
      if (state.me?.permissions.includes(Permissions.HOME)) {
        void router.replace({ name: RouteNameType.HOME })
      } else {
        void router.replace({ name: RouteNameType.ME })
      }
    })

    return {
      t,
      route,
      isDesktop,
      isMobile,
      getWebpClass,
      isPWA
    }
  }
})
</script>

<style lang="scss" scoped>
#layout {
  @apply absolute-full flex flex-col md:flex-row bg-[var(--gray-100)];
}
:global(.p-dialog .p-dialog-footer button:last-child) {
  @apply m-0;
}
:global(.p-tooltip-text) {
  @apply text-3;
}

:global(.p-dialog-mask) {
  // 某些情境沒有動態被賦予 style z-index
  @apply z-1001;
}

:global(.p-dialog) {
  @apply bg-white;
}

:global(.p-dialog .pi.pi-times) {
  @apply i-ri-close-line text-6;
}

:global(.p-toast.p-component) {
  @apply max-w-[calc(100%-1.5rem)] opacity-100;
}

:global(*:focus) {
  box-shadow: none !important;
}

:global(.p-overlaypanel .p-overlaypanel-content) {
  @apply important-p-0;
}

:global(.p-overlaypanel::before) {
  @apply hidden;
}

:global(.p-overlaypanel::after) {
  @apply hidden;
}

:global(.p-button-loading-icon) {
  @apply i-ri-loader-4-line;
  animation: spin 1s linear infinite;
}

:global(.pi.p-dropdown-trigger-icon) {
  @apply i-ri-arrow-down-s-line;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
