import { ActivityStatusType, RecommendFriendStateType, ActivityNewMemberBonusStateType, ActivityDailySignInStateType, ActivityNewYear2023StatusType, ActivityDragonBoatFestival2023StatusType, ActivityNewYear2024StatusType, ActivityDepositBonusStatusType, ActivityUefaBuildingUpStateType, ActivityUefaStarsStatusType, ActivityWeeklyBonusDrawStatusType, ActivityDailyBonusDrawStatusType, ActivityNewYear2025DrawStatusType } from '@golden/gdk-campaign'
import { PlayerStatusType, AgentType, AssistantType, TransactionStatus, DepositStatusType, WithdrawStatusType, OrderStatusType, ManualEventType, AgentDepositStatusType, AgentWalletTransactionType, AgentWalletTransferType } from '@golden/gdk-agent-next'

export const PlayerStatusName = {
  [PlayerStatusType.NORMAL]: 'userStatus.hasBindCard',
  [PlayerStatusType.SUSPENDED]: 'userStatus.suspended',
  [PlayerStatusType.NO_CARD]: 'userStatus.noBindCard'
}

export const PlayerStatusColor = {
  [PlayerStatusType.NORMAL]: 'text-green-600',
  [PlayerStatusType.SUSPENDED]: 'text-red-600',
  [PlayerStatusType.NO_CARD]: ''
}

export const TransactionStatusName = {
  [TransactionStatus.SUCCESSFUL]: 'common.success',
  [TransactionStatus.FAIL]: 'common.failed',
  [TransactionStatus.IN_PROGRESS]: 'common.inProgress'
}

export const TransactionStatusColor = {
  [TransactionStatus.SUCCESSFUL]: '',
  [TransactionStatus.FAIL]: '',
  [TransactionStatus.IN_PROGRESS]: ''
}

export const DepositStatusName = {
  [DepositStatusType.IN_PROGRESS]: 'common.inProgress',
  [DepositStatusType.SUCCESSFUL]: 'common.success',
  [DepositStatusType.CANCEL]: 'common.failed',
  [DepositStatusType.FAIL]: 'common.failed',
  [DepositStatusType.EXPIRED]: 'common.failed'
}

export const WithdrawStatusName = {
  [WithdrawStatusType.IN_PROGRESS]: 'common.inProgress',
  [WithdrawStatusType.REVIEWING]: 'common.inProgress',
  [WithdrawStatusType.SUCCESSFUL]: 'common.success',
  [WithdrawStatusType.THIRD_PARTY_WITHDRAWING]: 'common.inProgress',
  [WithdrawStatusType.REVIEW_FAIL]: 'common.failed',
  [WithdrawStatusType.THIRD_PARTY_PARTIAL_FAIL]: 'common.inProgress',
  [WithdrawStatusType.CANCEL_THIRD_PARTY]: 'common.inProgress',
  [WithdrawStatusType.THIRD_PARTY_FAIL]: 'common.inProgress',
  [WithdrawStatusType.THIRD_PARTY_HOOK_FAIL]: 'common.inProgress',
  [WithdrawStatusType.THIRD_PARTY_DETAIL_HOOK_FAIL]: 'common.inProgress',
  [WithdrawStatusType.CANCEL_BY_RE_PAYOUT]: 'common.failed',
  [WithdrawStatusType.FORWARDER_BALANCE_NOT_ENOUGH]: 'common.inProgress',
  [WithdrawStatusType.NO_WAY_CAN_USE]: 'common.inProgress',
  [WithdrawStatusType.AFTER_SPLITTING]: 'common.inProgress'
}

export const OrderStatusName = {
  [OrderStatusType.UNPAID]: 'orderStatus.unpaid',
  [OrderStatusType.WIN]: 'orderStatus.win',
  [OrderStatusType.LOSE]: 'orderStatus.lose',
  [OrderStatusType.CANCELED]: 'orderStatus.canceled',
  [OrderStatusType.CANCELED_BY_SYSTEM]: 'orderStatus.canceledBySystem',
  [OrderStatusType.TIE]: 'orderStatus.tie'
}

export const OrderStatusColor = {
  [OrderStatusType.UNPAID]: '',
  [OrderStatusType.WIN]: 'text-green-600',
  [OrderStatusType.LOSE]: 'text-red-600',
  [OrderStatusType.CANCELED]: '',
  [OrderStatusType.CANCELED_BY_SYSTEM]: '',
  [OrderStatusType.TIE]: ''
}

export const DepositStatusTypeName = {
  [DepositStatusType.SUCCESSFUL]: 'common.success',
  [DepositStatusType.FAIL]: 'common.failed',
  [DepositStatusType.IN_PROGRESS]: 'common.inProgress',
  [DepositStatusType.EXPIRED]: 'common.failed',
  [DepositStatusType.CANCEL]: 'common.failed'
}

export const DepositStatusTypeColor = {
  [DepositStatusType.SUCCESSFUL]: 'text-green-600',
  [DepositStatusType.FAIL]: 'text-red-600',
  [DepositStatusType.IN_PROGRESS]: '',
  [DepositStatusType.EXPIRED]: 'text-red-600',
  [DepositStatusType.CANCEL]: 'text-red-600'
}

export const AgentDepositStatusTypeName = {
  [AgentDepositStatusType.SUCCESSFUL]: 'common.success',
  [AgentDepositStatusType.FAIL]: 'common.failed',
  [AgentDepositStatusType.IN_PROGRESS]: 'common.inProgress'
}

export const AgentDepositStatusTypeColor = {
  [AgentDepositStatusType.SUCCESSFUL]: 'text-green-600',
  [AgentDepositStatusType.FAIL]: 'text-red-600',
  [AgentDepositStatusType.IN_PROGRESS]: ''
}

export const AgentWalletTransactionTypeName = {
  [AgentWalletTransactionType.IN]: 'agentWalletTransactionType.transfer',
  [AgentWalletTransactionType.OUT]: 'agentWalletTransactionType.transfer',
  [AgentWalletTransactionType.RECHARGE]: 'agentWalletTransactionType.recharge',
  [AgentWalletTransactionType.REVOKE]: 'agentWalletTransactionType.revoke',
  [AgentWalletTransactionType.DEPOSIT]: 'agentWalletTransactionType.deposit'
}

export const AgentWalletTransferTypeName = {
  [AgentWalletTransferType.DEPOSIT]: 'agentWalletTransferType.deposit',
  [AgentWalletTransferType.TRANSFER]: 'agentWalletTransferType.transfer',
  [AgentWalletTransferType.WITHDRAW]: 'agentWalletTransferType.withdraw'
}

export const LoginStatusName = {
  0: 'loginStatus.false',
  1: 'loginStatus.true'
}

export const LoginStatusColor = {
  0: 'text-red-600',
  1: 'text-green-600'
}

export const LayerName = {
  [AgentType.EMPEROR]: 'layer.emperor',
  [AgentType.BOSS]: 'layer.boss',
  [AgentType.MANAGER]: 'layer.manager',
  [AgentType.LEADER]: 'layer.leader',
  [AgentType.STAFF]: 'layer.staff',
  1000: 'layer.player'
}

export const NextLayer = {
  [AgentType.EMPEROR]: AgentType.BOSS,
  [AgentType.BOSS]: AgentType.MANAGER,
  [AgentType.MANAGER]: AgentType.LEADER,
  [AgentType.LEADER]: AgentType.STAFF,
  [AgentType.STAFF]: 1000 as const
}

export const AssistantName = {
  [AssistantType.ACCOUNT_VIEWER]: 'assistant.accountViewer',
  [AssistantType.INFO_VIEWER]: 'assistant.infoViewer',
  [AssistantType.REPORT_VIEWER]: 'assistant.reportViewer',
  [AssistantType.CUSTOM_VIEWER]: 'assistant.customViewer'
}

export const ActivityStatusName = {
  [ActivityStatusType.SUCCESS]: 'activityStatus.success',
  [ActivityStatusType.RECEIVED]: 'activityStatus.received',
  [ActivityStatusType.SENT]: 'activityStatus.sent',
  [ActivityStatusType.EXPIRED]: 'activityStatus.expired',
  [ActivityStatusType.CANCELED]: 'activityStatus.canceled'
}

export const RecommendFriendStatusName = {
  [RecommendFriendStateType.IN_PROGRESS]: 'activityStatus.success',
  [RecommendFriendStateType.RECEIVED]: 'activityStatus.sent',
  [RecommendFriendStateType.EXPIRED]: 'activityStatus.expired',
  [RecommendFriendStateType.CANCELED]: 'activityStatus.canceled'
}

export const NewMemberBonusStatusName = {
  [ActivityNewMemberBonusStateType.JOINED]: 'activityStatus.success',
  [ActivityNewMemberBonusStateType.BONUS_RECEIVED]: 'activityStatus.sent',
  [ActivityNewMemberBonusStateType.BONUS_EXPIRED]: 'activityStatus.expired',
  [ActivityNewMemberBonusStateType.CANCELED]: 'activityStatus.canceled'
}

export const NewYear2023StatusName = {
  [ActivityNewYear2023StatusType.NO_USE]: 'activityStatus.noUse',
  [ActivityNewYear2023StatusType.EXPIRED]: 'activityStatus.expired',
  [ActivityNewYear2023StatusType.RECEIVED]: 'activityStatus.sent'
}

export const DailySignInStateName = {
  [ActivityDailySignInStateType.APPLIED]: 'activityStatus.success',
  [ActivityDailySignInStateType.RECEIVED]: 'activityStatus.sent',
  [ActivityDailySignInStateType.EXPIRED]: 'activityStatus.expired',
  [ActivityDailySignInStateType.CANCELED]: 'activityStatus.canceled'
}

export const ManualEventTypeName = {
  [ManualEventType.DEPOSIT]: 'common.manualDeposit',
  [ManualEventType.WITHDRAW]: 'common.manualWithdraw'
}

export const DragonBoatFestival2023StatusName = {
  [ActivityDragonBoatFestival2023StatusType.NO_USE]: 'activityStatus.noUse',
  [ActivityDragonBoatFestival2023StatusType.EXPIRED]: 'activityStatus.expired',
  [ActivityDragonBoatFestival2023StatusType.LOST]: 'activityStatus.lost',
  [ActivityDragonBoatFestival2023StatusType.RECEIVED]: 'activityStatus.win',
  [ActivityDragonBoatFestival2023StatusType.CANCELED]: 'activityStatus.canceled'
}

export const NewYear2024StatusName = {
  [ActivityNewYear2024StatusType.LOST]: 'activityStatus.lost',
  [ActivityNewYear2024StatusType.RECEIVED]: 'activityStatus.sent'
}

export const NewYear2025DrawStatusName = {
  [ActivityNewYear2025DrawStatusType.NO_USE]: 'activityStatus.noUse',
  [ActivityNewYear2025DrawStatusType.NOT_RECEIVED]: 'activityStatus.notReceived',
  [ActivityNewYear2025DrawStatusType.RECEIVED]: 'activityStatus.received',
  [ActivityNewYear2025DrawStatusType.LOSE]: 'activityStatus.lost',
  [ActivityNewYear2025DrawStatusType.EXPIRED]: 'activityStatus.expired'
}

export const DepositBonusStatusName = {
  [ActivityDepositBonusStatusType.RECEIVED]: 'activityStatus.sent'
}

export const UefaBuildingUpStateName = {
  [ActivityUefaBuildingUpStateType.APPLIED]: 'activityStatus.success',
  [ActivityUefaBuildingUpStateType.RECEIVED]: 'activityStatus.received',
  [ActivityUefaBuildingUpStateType.CANCELED]: 'activityStatus.canceled',
  [ActivityUefaBuildingUpStateType.EXPIRED]: 'activityStatus.expired'
}

export const UefaStarsStatusName = {
  [ActivityUefaStarsStatusType.NO_USE]: 'activityStatus.noUse',
  [ActivityUefaStarsStatusType.WIN]: 'activityStatus.win',
  [ActivityUefaStarsStatusType.LOST]: 'activityStatus.lost',
  [ActivityUefaStarsStatusType.EXPIRED]: 'activityStatus.expired',
  [ActivityUefaStarsStatusType.RECEIVED]: 'activityStatus.received',
  [ActivityUefaStarsStatusType.NOT_RECEIVED]: 'activityStatus.notReceived'
}

export const WeeklyBonusDrawStatusName = {
  [ActivityWeeklyBonusDrawStatusType.EXPIRED]: 'activityStatus.expired',
  [ActivityWeeklyBonusDrawStatusType.RECEIVED]: 'activityStatus.received',
  [ActivityWeeklyBonusDrawStatusType.NOT_RECEIVED]: 'activityStatus.notReceived'
}

export const DailyBonusDrawStatusName = {
  [ActivityDailyBonusDrawStatusType.EXPIRED]: 'activityStatus.expired',
  [ActivityDailyBonusDrawStatusType.RECEIVED]: 'activityStatus.received',
  [ActivityDailyBonusDrawStatusType.NOT_RECEIVED]: 'activityStatus.notReceived'
}
