<template>
  <div class="bg-white absolute-full flex-row-center">
    <prime-progress-spinner stroke-width="4" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter, isNavigationFailure } from 'vue-router'
import gdk from '@/gdk'
import { useObservable, LoginStatusStorage } from '@golden/shared-vue'
import { Branch } from '@golden/gdk-agent-next'
import { filter, take, tap, race, from, switchMap, lastValueFrom } from 'rxjs'
import { StartupStorage } from '@golden/app-plugins'
import { checkAPI, checkNetwork, isDomainError } from '@golden/domain'
import { urlHelper } from '@/utils/urlHelper'

export default defineComponent({
  name: 'PrepareView',
  props: {
    toPath: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const router = useRouter()
    const isFirst = ref(!StartupStorage.getInstance().launch)

    const checkAvailable = async () => {
      if (!isFirst.value) return
      try {
        await lastValueFrom(checkAPI(urlHelper, 'agent', 'v2'))
        StartupStorage.getInstance().launch = true
      } catch (e) {
        if (isDomainError(e)) {
          if (e.type === 'Maintenance') {
            const { comment, title: _title } = e?.debug?.data as { comment: string, title?: string }
            const title = decodeURIComponent(_title ?? '例行维护')
            window.location.replace(`${import.meta.env.BASE_URL}maintenance.html?time=${comment}&title=${title}`)
            throw e
          }
          if (e.type === 'Unknown' && !(await lastValueFrom(checkNetwork()))) {
            void router.replace({ path: '/no-network' })
            throw new Error('No available network')
          }
          void router.replace({ path: '/load-failed' })
          throw new Error('No available domain')
        }
        throw e
      }
    }

    const checkLogin = () =>
      race(
        gdk.isLoginSubject.pipe(filter((data) => !data)),
        gdk.agent.me.pipe(filter((data) => !!data && data.account.length !== 0))
      ).pipe(
        take(1),
        tap(() => { LoginStatusStorage.isCheck = true })
      )

    const afterCheck = () => {
      router.replace(props.toPath).catch((error) => {
        if (!isNavigationFailure(error, 2) && !isNavigationFailure(error, 1)) {
          void router.replace('/')
          throw error
        }
      })
    }

    useObservable(
      from(checkAvailable()).pipe(
        switchMap(() => checkLogin()),
        tap(afterCheck)
      ),
      null,
      () => {}
    )

    gdk.trunk.next([Branch.ME])
    return {}
  }
})
</script>

<style lang="scss" scoped>
:deep(.p-progress-spinner-circle) {
  -webkit-animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
    p-progress-spinner-color-custom 6s ease-in-out infinite;
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
    p-progress-spinner-color-custom 6s ease-in-out infinite;
}

@keyframes p-progress-spinner-color-custom {
  100%,
  0% {
    stroke: var(--primary-color);
  }
}
</style>
