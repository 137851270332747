import { defineStore } from 'pinia'
import { PlayersReq, CommissionReq, BetReq, TransactionReq, AgentType, ActivityReq } from '@golden/gdk-agent-next'

export interface ReportQuery {
  id?: number
  account?: string
  layer: AgentType
}
export interface State {
  player: Partial<PlayersReq<number> & { account?: string, agent_id?: number } >
  commission: Partial<CommissionReq<number> & ReportQuery>
  bet: Partial<BetReq<number> & ReportQuery>
  activity: Partial<ActivityReq<number> & ReportQuery>
  transaction: Partial<TransactionReq<number> & ReportQuery>
}

export const useQueryStore = defineStore('query', {
  state: (): State => {
    return {
      // 會員
      player: {},
      // 佣金
      commission: {},
      // 注單
      bet: {},
      // 活動
      activity: {},
      // 充題
      transaction: {}
    }
  },
  actions: {
    setPlayer (value: State['player']) {
      this.player = JSON.parse(JSON.stringify(value)) as PlayersReq<number>
    },
    setCommission (value: State['commission']) {
      this.commission = JSON.parse(JSON.stringify(value)) as CommissionReq<number>
    },
    setBet (value: State['bet']) {
      this.bet = JSON.parse(JSON.stringify(value)) as object
    },
    setActivity (value: State['activity']) {
      this.activity = JSON.parse(JSON.stringify(value)) as object
    },
    setTransaction (value: State['transaction']) {
      this.transaction = JSON.parse(JSON.stringify(value)) as object
    }
  },
  persist: {
    enabled: true,
    strategies: [{ storage: localStorage }]
  }
}
)
