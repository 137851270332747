<template>
  <prime-button
    icon="i-ri-filter-line"
    :label="label"
    :badge="badge !== 0 ? `${badge}` : ''"
    :class="{ 'p-button-outlined': badge === 0, 'p-button-sm': true }"
    @click="() => visible = true"
  />
  <base-media-query-dialog
    v-model:visible="visible"
    class="filter-dialog"
    @after-hide="afterHide"
  >
    <slot />
    <template
      #header
    >
      <div class="flex-row-between w-full">
        <PrimeButton
          v-if="isMobile"
          :label="t('common.reset')"
          class="p-button-text"
          @click="reset"
        />
        <PrimeButton
          v-if="isMobile"
          :label="t('common.filter')"
          @click="submit"
        />
      </div>
    </template>
    <template
      v-if="isDesktop"
      #footer
    >
      <div class="flex-row-between">
        <PrimeButton
          :label="t('common.reset')"
          class="p-button-text"
          @click="reset"
        />
        <PrimeButton
          :label="t('common.filter')"
          @click="submit"
        />
      </div>
    </template>
  </base-media-query-dialog>
</template>

<script setup lang="ts">
import { defineOptions, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import i18n from '@/i18n'
import { useMediaQueryStore } from '@/stores/screen'
import { storeToRefs } from 'pinia'

defineOptions({
  name: 'BaseFilterButton'
})

withDefaults(defineProps<{
  label?: string,
  badge?: number
}>(), {
  label: i18n.global.t('common.filter'),
  badge: 0
})

const emit = defineEmits(['reset', 'submit', 'after-hide'])

const { t } = useI18n()
const visible = ref(false)
const { isDesktop, isMobile } = storeToRefs(useMediaQueryStore())
const reset = () => {
  emit('reset')
  visible.value = false
}

const submit = () => {
  emit('submit')
  visible.value = false
}

const afterHide = () => {
  emit('after-hide')
}
</script>

<style lang="scss" scoped>
:global(.p-dialog.filter-dialog .p-dialog-header) {
  @apply py-10px md:py-24px px-15px;
}
:global(.p-dialog.filter-dialog .p-dialog-footer) {
  @apply py-10px px-15px;
}
:global(.p-dialog.filter-dialog .p-dialog-footer button) {
  @apply m-0;
}
</style>
