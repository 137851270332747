<template>
  <prime-button
    :icon="showIcon ? 'i-ri-calendar-line' : ''"
    :label="formatLabel"
    :class="{ 'p-button-outlined': !modelValue, 'p-button-sm': true, 'p-button-text': isText, 'important-p-0': isText, 'fw-noraml': isText }"
    @click="openCalendar"
  >
    <template v-if="isText">
      <span class="text-4">
        {{ formatLabel }}
      </span>
    </template>
  </prime-button>
  <prime-dialog
    v-model:visible="calendarIsOpen"
    class="calendar-dialog shadow-none w-450px"
    :modal="true"
    :show-header="false"
    dismissable-mask
    :breakpoints="breakpoints"
  >
    <v-date-picker
      v-model="calendarValue"
      v-bind="options"
      class="datepicker"
      @dayclick="handleDayClick"
    />
    <div
      v-if="shortcuts.length"
      class="flex-row-start p-3"
    >
      <prime-button
        v-for="shortcut in shortcuts"
        :key="shortcut.label"
        :label="shortcut.label"
        class="break-keep text-sm md:text-base p-0 p-button-link link mr-3"
        @click="shortcut.callback"
      />
      <prime-button
        v-if="clearable"
        :label="t('common.clear')"
        class="break-keep text-sm md:text-base p-0 p-button-link link ml-auto"
        @click="handleReset"
      />
    </div>
  </prime-dialog>
</template>

<script setup lang="ts">
import { defineOptions, computed, ref, ComputedRef, defineAsyncComponent, DefineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { startOfDayTaipei, endOfDayTaipei, setToday, setYesterday, setThisWeek, setLastWeek, setThisMonth, setLastMonth, DateShortcutType, setNextOneWeek, setNextTwoWeeks, setNextThisWeek, setNextOneMonth, setNextTwoMonths, getTaipeiTime, formatTaipeiTime } from '@/utils/TimeHelper'
import { BreakPoints } from '@/constants/BreakPoints'
import 'v-calendar/dist/style.css'
import { cloneDeep } from '@golden/utils'

defineOptions({
  name: 'BaseDateButton',
  components: {
    VDatePicker: defineAsyncComponent(async () => (await import('v-calendar')).DatePicker) as DefineComponent
  }
})

const props = withDefaults(defineProps<{
  showIcon?: boolean,
  label?: string,
  active?: boolean,
  isText?: boolean,
  clearable?: boolean,
  yearNavigator?: boolean,
  minDate?: Date,
  maxDate?: Date,
  dateFormat?: string,
  selectionMode?: 'single' | 'multiple' | 'range',
  view?: 'date' | 'month' | 'year',
  defaultValue?: Date[] | Date | null,
  showShortcuts?: DateShortcutType[],
  modelValue?: Date[] | Date | null
}>(), {
  showIcon: true,
  label: '',
  active: false,
  isText: false,
  clearable: true,
  yearNavigator: true,
  minDate: undefined,
  maxDate: undefined,
  dateFormat: 'yyyy/MM/dd',
  selectionMode: 'range',
  view: 'date',
  defaultValue: null,
  showShortcuts: () => (['today', 'thisWeek', 'thisMonth', 'lastMonth']),
  modelValue: null
})

const emit = defineEmits(['update:modelValue', 'open', 'close', 'dayClick'])

const breakpoints = { [`${BreakPoints.md}px`]: '90vw' }
const { t } = useI18n()

const calendarIsOpen = ref(false)

const openCalendar = () => {
  calendarIsOpen.value = true
  emit('open')
}
const closeCalendar = () => {
  calendarIsOpen.value = false
  emit('close')
}
const setCalendarValue = (value: Date[] | Date | null) => {
  emit('update:modelValue', value)
  closeCalendar()
}

const calendarValue = computed({
  get: () => {
    const value = props.modelValue
    if (!value) return null
    if (Array.isArray(value)) return { start: value[0], end: value[1] }
    return value
  },
  set: (value) => {
    if (!value) {
      setCalendarValue(value)
      return
    }
    if (!!value && 'start' in value) {
      setCalendarValue([startOfDayTaipei(getTaipeiTime(value.start)), endOfDayTaipei(getTaipeiTime(value.end))])
      return
    }
    setCalendarValue(getTaipeiTime(value))
  }
})

const formatLabel = computed(() => {
  if (!props.modelValue) {
    return props.label
  }
  if (Array.isArray(props.modelValue)) return `${props.label} ${formatTaipeiTime(props.modelValue[0], props.dateFormat)} ~ ${formatTaipeiTime(props.modelValue[1], props.dateFormat)}`
  return `${props.label} ${formatTaipeiTime(props.modelValue, props.dateFormat)}`
})

const handleReset = () => {
  setCalendarValue(cloneDeep(props.defaultValue))
}

const handleDayClick = (day: { range: { start: Date, end: Date } }) => {
  emit('dayClick', [day.range.start, day.range.end])
}

const shortcutsMap: ComputedRef<{ [key in DateShortcutType]: { label: string, callback: () => void } }> = computed(() => (
  {
    today: {
      label: t('common.today'),
      callback: () => handleShortcut(setToday)
    },
    yesterday: {
      label: t('common.yesterday'),
      callback: () => handleShortcut(setYesterday)
    },
    thisWeek: {
      label: t('common.thisWeek'),
      callback: () => handleShortcut(setThisWeek)
    },
    nextThisWeek: {
      label: t('common.thisWeek'),
      callback: () => handleShortcut(setNextThisWeek)
    },
    lastWeek: {
      label: t('common.lastWeek'),
      callback: () => handleShortcut(setLastWeek)
    },
    nextOneWeek: {
      label: t('common.nextOneWeek'),
      callback: () => handleShortcut(setNextOneWeek)
    },
    nextTwoWeeks: {
      label: t('common.nextTwoWeeks'),
      callback: () => handleShortcut(setNextTwoWeeks)
    },
    thisMonth: {
      label: t('common.thisMonth'),
      callback: () => handleShortcut(setThisMonth)
    },
    lastMonth: {
      label: t('common.lastMonth'),
      callback: () => handleShortcut(setLastMonth)
    },
    nextOneMonth: {
      label: t('common.nextOneMonth'),
      callback: () => handleShortcut(setNextOneMonth)
    },
    nextTwoMonths: {
      label: t('common.nextTwoMonths'),
      callback: () => handleShortcut(setNextTwoMonths)
    }
  }
))
const shortcuts = computed(() => props.showShortcuts.map((item) => shortcutsMap.value[item]))
const handleShortcut = (getDate: () => ({ start: Date, end: Date })) => {
  const { start, end } = getDate()
  if (props.selectionMode === 'range') {
    setCalendarValue([start, end])
    return
  }
  setCalendarValue(start)
}

const SELECT_STYLE = {
  highlight: ['start', 'base', 'end'].reduce<Record<string, object>>((pre, cur) => ({
    ...pre,
    [cur]: { style: { backgroundColor: '#eff6ff' }, contentStyle: { color: '#1d4eb8' } }
  }), {})
}

const options = computed(() => {
  return {
    color: 'gray',
    'select-attribute': SELECT_STYLE,
    'drag-attribute': SELECT_STYLE,
    attributes: [
      { key: 'today', highlight: { style: { backgroundColor: '#ced4da' } }, dates: new Date() }
    ],
    'from-date': !calendarValue.value
      ? props.maxDate
      : 'end' in calendarValue.value ? calendarValue.value.end : calendarValue.value,
    'min-date': props.minDate,
    'max-date': props.maxDate,
    'first-day-of-week': 1,
    'is-range': props.selectionMode === 'range',
    'disable-page-swipe': true,
    masks: { title: 'MMMM YYYY', weekdays: 'WW' },
    locale: 'en',
    'is-expanded': true,
    timezone: 'Asia/Taipei'
  }
})

</script>

<style lang="scss" scoped>
:global(.p-dialog.calendar-dialog .p-dialog-content ) {
  @apply p-2 bg-transparent;
}
.datepicker {
  @apply color-[var(--text-color)] b-none;
  --font-medium: 400;
  :deep(.vc-arrows-container .vc-arrow) {
    @apply w-7 h-7 p-2 rounded-50%;
  }
  :deep(.vc-header) {
    @apply p-2 b-b-#dee2e6 b-b-1px b-b-solid;
    .vc-title {
      @apply text-1rem lh-2rem color-[var(--text-color)];
    }
  }
  :deep(.vc-weekday) {
    @apply my-2 color-[var(--text-color)];
  }
  :deep(.vc-day) {
    @apply my-2;
    .vc-day-content {
      @apply w-10 h-10;
    }
  }
}
</style>
