<template>
  <span :class="className">
    {{ t(nameMap[status]) }}
  </span>
</template>

<script setup lang="ts">
import { defineOptions, computed } from 'vue'
import { useI18n } from 'vue-i18n'

type Status = string | number
type StatusMap = Record<Status, string>

defineOptions({
  name: 'BaseStatus'
})

const props = withDefaults(defineProps<{
  nameMap: StatusMap,
  status: Status,
  colorMap?: StatusMap
}>(), {
  colorMap: undefined
})

const { t } = useI18n()
const className = computed(() => {
  if (props.colorMap) return props.colorMap[props.status]
  return ''
})
</script>
