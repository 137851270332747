import { h, Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDialog as usePrimeDialog } from 'primevue/usedialog'
import { useToast } from 'primevue/usetoast'
import PrimeButton from 'primevue/button'
import { useDefaultGDKErrorHandler } from '@golden/shared-vue'
import { GDKError, GDKFormError } from '@golden/gdk-agent-next'

interface DialogData {
  disabled: Ref<boolean>
  loading: Ref<boolean>
  header: Ref<string>
  data: Ref<string>
  errorHandler: (err: GDKError | GDKFormError) => void
  dialogData: unknown
}

export const useDialog = () => {
  const dialog = usePrimeDialog()
  const toast = useToast()
  const { t } = useI18n()
  const confirm = async (
    component: unknown,
    options?: { width?: string, header?: string, cancelLabel?: string, confirmLabel?: string, loading?: Ref<boolean>, callback?: (ref: { close: () => void }) => void }
  ) => {
    return await new Promise((resolve, reject) => {
      const dialogRef = dialog.open(typeof component === 'string' ? h('div', { class: { 'break-words': true } }, component) : component, {
        props: {
          style: {
            width: options?.width ?? '360px'
          },
          modal: true,
          dismissableMask: true
        },
        templates: {
          header: () => h('h3', { class: 'm-0' }, options?.header ?? t('common.tip')),
          footer: () => {
            return ([
              h(PrimeButton, { label: options?.cancelLabel ?? t('common.cancel'), onClick: () => dialogRef.close({ buttonType: 'cancel' }), class: 'p-button-text' }),
              h(PrimeButton, {
                label: options?.confirmLabel ?? t('common.confirm'),
                onClick: options?.callback ? () => options?.callback?.(dialogRef) : () => dialogRef.close({ buttonType: 'confirm' }),
                loading: options?.loading?.value ?? false,
                autofocus: true
              })
            ])
          }
        },
        onClose: (options) => {
          const data = options?.data as { buttonType: 'cancel' | 'confirm' }
          if (data?.buttonType === 'confirm') {
            resolve(data)
          } else {
            reject(data)
          }
        }
      })
    })
  }
  const alert = async (component: unknown, options?: { width?: string, header?: string, cancelLabel?: string, confirmLabel?: string }) => {
    return await new Promise((resolve, reject) => {
      const dialogRef = dialog.open(typeof component === 'string' ? h('div', { class: { 'break-words': true } }, component) : component, {
        props: {
          style: {
            width: options?.width ?? '360px'
          },
          modal: true,
          dismissableMask: true
        },
        templates: {
          header: () => h('h3', { class: 'm-0' }, options?.header ?? t('common.tip')),
          footer: () => {
            return ([
              h(PrimeButton, {
                label: options?.confirmLabel ?? t('common.confirm'),
                onClick: () => dialogRef.close({ buttonType: 'confirm' }),
                autofocus: true
              })
            ])
          }
        },
        onClose: (options) => {
          const data = options?.data as { buttonType: 'cancel' | 'confirm' }
          if (data?.buttonType === 'confirm') {
            resolve(data)
          } else {
            reject(data)
          }
        }
      })
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setData = (component: unknown, options: { id: number, data: string, dialogData: Record<string, any>, callback: (payload: { data: string, id: number, dialogData: unknown }) => Promise<unknown>, next?: (data: any) => void, msg?: string }) => {
    const dialogRef = dialog.open(component, {
      props: {
        style: {
          width: '360px'
        },
        modal: true,
        dismissableMask: true
      },
      data: {
        disabled: ref(false),
        loading: ref(false),
        header: ref(''),
        data: ref(options.data),
        errorHandler: useDefaultGDKErrorHandler(),
        dialogData: { ...options.dialogData }
      },
      templates: {
        header: () => h('h3', { class: 'm-0' }, (dialogRef.data as DialogData).header.value),
        footer: () => {
          return ([
            h(PrimeButton, { label: t('common.cancel'), onClick: () => dialogRef.close({ buttonType: 'cancel' }), class: 'p-button-text' }),
            h(PrimeButton, {
              label: t('common.confirm'),
              disabled: (dialogRef.data as DialogData).disabled.value,
              loading: (dialogRef.data as DialogData).loading.value,
              onClick: () => {
                void (async () => {
                  (dialogRef.data as DialogData).loading.value = true
                  await options.callback({ data: (dialogRef.data as DialogData).data.value, id: options.id, dialogData: (dialogRef.data as DialogData).dialogData })
                    .then((options.next))
                    .then(() => {
                      dialogRef.close({ buttonType: 'confirm' })
                      if (options.msg === '') return
                      toast.add({ severity: 'success', detail: options.msg ?? t('message.common.setSuccess'), life: 1000 })
                    })
                    .catch((dialogRef.data as DialogData).errorHandler)
                    .finally(() => {
                      (dialogRef.data as DialogData).loading.value = false
                    })
                })()
              },
              autofocus: true
            })
          ])
        }
      }
    })
  }

  return {
    confirm,
    alert,
    setData
  }
}
