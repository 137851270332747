import { defineStore, storeToRefs } from 'pinia'
import { PlayersSearchRes, AccountSearchRes } from '@golden/gdk-agent-next'
import { useAgentStore } from '@/stores/agent'

export interface State {
  playersHistory: Record<number, PlayersSearchRes[]>
  commissionHistory: Record<number, AccountSearchRes[]>
  betHistory: Record<number, AccountSearchRes[]>
  activityHistory: Record<number, AccountSearchRes[]>
  transactionHistory: Record<number, AccountSearchRes[]>
}

export const useHistoryStore = defineStore('history', {
  state: (): State & { needSetFlag: boolean } => {
    return {
      needSetFlag: false,
      // 會員
      playersHistory: {},
      // 佣金
      commissionHistory: {},
      // 注單
      betHistory: {},
      // 活動
      activityHistory: {},
      // 充提
      transactionHistory: {}
    }
  },
  actions: {
    addHistory <T extends { id: number }> (key: keyof State, value: T) {
      const { me } = storeToRefs(useAgentStore())
      const meId = me.value?.id as number
      if (!this[key][meId]) {
        this[key][meId] = []
      }
      const history = this[key][meId] as unknown as T[]
      const index = history.findIndex(item => item.id === value.id)
      if (index !== -1) {
        history.splice(index, 1)
      }
      history.unshift(value)

      if (history.length > 10) {
        history.pop()
      }
      this.needSetFlag = false

      return history
    },
    removeHistory <T extends keyof State> (history: T, id: number) {
      const { me } = storeToRefs(useAgentStore())
      const meId = me.value?.id as number
      const index = this[history][meId]?.findIndex(item => item.id === id)
      if (index !== -1) {
        this[history][meId]?.splice(index, 1)
      }
    }
  },
  persist: {
    enabled: true,
    strategies: [{ storage: localStorage }]
  }
}
)
