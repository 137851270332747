import { BcMath, RoundingMode } from '@golden/bcmath'
import i18n from '@/i18n'
import { differenceInYears, differenceInDays } from 'date-fns'
import { CommissionResDetail } from '@golden/gdk-agent-next'
import { formatTaipeiTime, getTaipeiNowTime } from './TimeHelper'

export const formatRoundDown = (value: string | number, style?: 'currency' | 'decimal' | 'usdt') => {
  return Number(BcMath.base(value).round(style === 'usdt' ? 4 : 2, RoundingMode.RoundDown).get())
}

export const formatCurrency = (value: string | number, options?: { style?: 'currency' | 'decimal' | 'usdt' }) => {
  const style = options?.style ?? 'decimal'
  return i18n.global.n(formatRoundDown(value, style), style)
}

export const formatPercent = (value: string | number) => {
  return `${i18n.global.n(Number(value), { minimumFractionDigits: 0, maximumFractionDigits: 2 })}%`
}

export const formatDate = (date: Date | number | null) => {
  if (!date) return '-'
  const differenceYears = differenceInYears(getTaipeiNowTime(), date)
  if (differenceYears > 0) return i18n.global.t('common.overOneYear')

  const differenceDays = differenceInDays(getTaipeiNowTime(), date)
  if (differenceDays > 0) return i18n.global.t('common.daysAgo', { day: differenceDays })

  return formatTaipeiTime(date, 'yyyy/MM/dd HH:mm:ss')
}

export const formatFullDate = (date: Date | null) => {
  if (!date) return '-'
  return formatTaipeiTime(date, 'yyyy/MM/dd HH:mm:ss')
}

export const formatBooleanValue = (value: boolean) => {
  return value ? 'common.yes' : 'common.no'
}

export const formatBooleanColor = (value: boolean) => {
  return value ? 'text-green-600' : 'text-red-600'
}

export const formatCurrencyColor = (value: number) => {
  if (value > 0) return 'text-green-600'
  if (value < 0) return 'text-red-600'
  return ''
}

export const formatCurrencyBgColor = (value: number) => {
  if (value > 0) return 'bg-green-100'
  if (value < 0) return 'bg-red-100'
  return ''
}

export const formatHomeChartValue = (value: string | number) => Number(BcMath.base(value).round(2, RoundingMode.RoundDown).get())

export const formatCommissionDetails = <D extends CommissionResDetail>(details: D[] | null) => {
  if (!details) {
    return {
      details: null,
      percentageText: '-'
    }
  }
  const isChanged = details.some((el, _, arr) => el.percentage !== arr?.[0]?.percentage)
  return {
    details: isChanged ? details : null,
    percentageText: isChanged ? details.map((el) => formatPercent(el.percentage ?? 0)).join('、') : formatPercent(details?.[0]?.percentage ?? 0)
  }
}
