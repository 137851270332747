<template>
  <div
    class="ml-2"
    :style="{ height: `${size}px`, width: `${size}px` }"
  >
    <prime-button
      v-if="!hidden"
      icon="i-ri-arrow-right-s-line color-white"
      class="p-button-rounded p-button-text text-8 important-bg-#3B82F6 important:h-full important:w-full important:p-0"
    />
  </div>
</template>

<script setup lang="ts">
import { defineOptions } from 'vue'
import { useMediaQueryStore } from '@/stores/screen'
import { storeToRefs } from 'pinia'

defineOptions({
  name: 'BaseLinkIcon'
})

withDefaults(defineProps<{
  size?: number,
  hidden?: boolean
}>(), {
  size: 20,
  hidden: false
})

const { isDesktop } = storeToRefs(useMediaQueryStore())
</script>
