<template>
  <div
    class="py-4 flex-row-between md:justify-start border-truegray-200 border-b-solid border-b last:border-b-0"
    @click="click"
  >
    <span class="label w-120px mr-6 flex-shrink-0 lh-normal break-keep my-auto">
      <slot name="label">
        {{ label }}
      </slot>
    </span>
    <span
      class="value relative flex-1 flex-row-end min-w-0 flex-wrap md:justify-start"
      :style="{ width }"
      @click="valueClick"
    >
      <base-skeleton
        :loading="loading"
        :options="skeltionOptions"
      >
        <slot />
        <span
          v-if="$slots.tip"
          class="text-sm truncate w-full mt-2px mr-30px text-right md:text-left"
          :class="{ 'p-error': error }"
        >
          <slot name="tip" />
        </span>
      </base-skeleton>
    </span>
  </div>
</template>

<script setup lang="ts">
import { defineOptions } from 'vue'
import { useMediaQueryStore } from '@/stores/screen'
import { storeToRefs } from 'pinia'

defineOptions({
  name: 'BaseListItem'
})

withDefaults(defineProps<{
  label?: string,
  showBorder?: boolean,
  loading?: boolean,
  error?: boolean,
  skeltionOptions?: Record<string, string>,
  width?: string
}>(), {
  label: '',
  showBorder: false,
  loading: false,
  error: false,
  skeltionOptions: () => ({ height: '20px', width: '100px' }),
  width: undefined
})

const emit = defineEmits(['click', 'value-click'])

const { isMobile } = storeToRefs(useMediaQueryStore())
const click = () => {
  if (!isMobile.value) return
  emit('click')
}

const valueClick = () => {
  emit('value-click')
}
</script>

<style lang="scss" scoped>
:deep(.p-component:not(button)) {
  @apply disabled:op-100;
}
:deep(.p-inputtext) {
  @apply important-p-0;
}
</style>
